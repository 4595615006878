@import '../../../styles/settings';
@import '../../../styles/ci/mixins';
@import '../../../Magento_GroupedProduct/styles/view-toggle';
@import '../../../Magento_Catalog/styles/product-grid-list';
@import '../../../Magento_Catalog/styles/product-pricing';

.catalogsearch-result-index {
    .main-content {
        max-width: $global-width;
        margin-left: auto;
        margin-right: auto;
    }
}
.algolia-instant-selector-results {
    .algolia-infos {
        background: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding: 1rem;
        border-bottom: 2px solid $dark-gray;
        color: $dark-gray;
        font-size: rem-calc(16);
    }
    .ais-SearchBox-input {
        padding: rem-calc(10.5 15);
        height: auto;
        width: 100%;
        margin-top: 0.5rem;
    }
    .ais-SearchBox-reset {
        top: 5px;
        right: 5px;
        .ais-SearchBox-resetIcon {
            fill: $medium-gray;
        }
        &:hover {
            .ais-SearchBox-resetIcon {
                fill: get-color(secondary);
            }
        }
    }
    .ais-Hits-item {
        text-indent: 0;
        text-align: left;
        &:before {
            display: none;
        }
        mark {
            color: $dark-gray;
            background-color: rgba(adjust-hue(get-color('light-yellow'), 12deg), 0.5);
            font-weight: 700;
        }
    }
}


.product-list-image img {
    width: inherit;
}

.ais-SortBy-select, .ais-HitsPerPage-select {
    margin: 0;
}
.brand-wrapper {
    .brand-list {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        &.featured {
            font-size: rem-calc(20);
        }
        li {
            margin: 1rem;
            &:before {
                display: none;
            }
            a {
                color: darken($medium-gray, 8%);
                &:hover {
                    color: $dark-gray;
                    text-decoration: none;
                }
            }
        }

    }
}

@media screen and #{breakpoint(medium)} {
    .brand-wrapper {
        .brand-list {
            li {
                margin: 0.5rem 1rem;
            }
        }
    }
}
