.toolbar-wrapper .toggle-switch, .toolbar-wrapper .toolbar-label {
  vertical-align: middle;
}

.section-wrapper {
  margin: 3rem auto 0;
}
.section-wrapper .printer-section-title {
  color: #3D3B3B;
  margin-bottom: 1rem;
}

.ko-product .toolbar-wrapper.row {
  background: transparent;
  border-bottom: 2px solid #707070;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.ko-product .toolbar-wrapper.row .toolbar-header {
  color: #3D3B3B;
}

.toolbar-wrapper .toolbar-header {
  color: #ffffff;
}
.toolbar-wrapper .toolbar-label {
  margin-right: 1rem;
  font-weight: 600;
  font-size: 1.1428571429rem;
}
.toolbar-wrapper .toggle-switch {
  color: #aaaaaa;
  font-size: 1.8571428571rem;
  cursor: pointer;
  margin: 0 0.5rem;
}
[data-whatinput=mouse] .toolbar-wrapper .toggle-switch {
  outline: 0;
}
.toolbar-wrapper .toggle-switch:hover, .toolbar-wrapper .toggle-switch.active {
  color: #1E7EB0;
}
.toolbar-wrapper .toggle-switch.last {
  margin-right: 0;
}
.toolbar-wrapper .limiter-label {
  display: inline;
}

@media screen and (min-width: 48em) {
  .section-wrapper {
    border-bottom: 2px solid #302e2e;
    padding: 1rem;
    margin: 2rem auto 0;
  }
  .section-wrapper.printer-section-wrapper {
    border-bottom: 0;
    background: #302e2e;
    margin: 4rem auto 0;
  }
  .section-wrapper .printer-section-title {
    color: #ffffff;
    margin-bottom: 0;
  }
  .toolbar-amount {
    font-size: 1.1428571429rem;
  }
}
.printer-product-list {
  margin: 1rem auto 2rem;
}

.popular-list-wrapper {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.popular-list-wrapper.printer .product-list-cell {
  padding: 1rem;
}

.product-list-wrapper {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.product-list-wrapper .label {
  margin-right: 7rem;
}
.product-list-image {
  text-align: center;
  margin: 0 auto;
  position: relative;
}
.product-list-image img {
  width: 200px;
}
.product-list-image .xl-tooltip {
  position: absolute;
  bottom: 0.7142857143rem;
  left: 0;
}
.product-list-image .xl-badge {
  cursor: help;
  background: #cdcdcd;
  padding: 0.4285714286rem;
  display: block;
  border-radius: 50%;
  width: 2.5714285714rem;
  height: 2.5714285714rem;
  box-shadow: 1px 1px 2px 0 rgba(34, 48, 61, 0.3);
}
.product-list-image .xl-badge:before {
  content: url("../../images/icons/high-yield.svg");
  width: 1.7142857143rem;
  height: 1.7142857143rem;
  display: block;
  filter: invert(1) brightness(0.35);
}
.product-list-image .xl-badge:hover + .xl-tooltip-content {
  visibility: visible;
}
.product-list-image .xl-tooltip-content {
  visibility: hidden;
  color: #ffffff;
  position: absolute;
  z-index: 1;
  width: 14.2857142857rem;
  font-size: 1.0714285714rem;
  background: #22303d;
  padding: 0.7142857143rem;
  border-radius: 0;
  bottom: 0;
  left: -6.0714285714rem;
  margin-bottom: 3.2142857143rem;
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
}
.product-list-image .xl-tooltip-content:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #22303d;
  position: absolute;
  right: 50%;
  bottom: -20px;
  margin-right: -10px;
  z-index: 3;
}

.items-in-cart {
  margin: 0.75rem 0.5rem 0 0;
  text-align: right;
}

.atc-qty {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-top: 0;
}
.atc-qty-wrapper {
  flex: 0 0 auto;
  max-width: 100%;
}
.atc-qty-num {
  width: 78px;
  margin: 0;
}
.atc-button {
  width: 100%;
  padding: 1rem 0;
}
.atc-button-wrapper {
  margin-left: 1rem;
}

.list-view, .grid-view, .small-grid-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.list-view .product-list-cell, .grid-view .product-list-cell, .small-grid-view .product-list-cell {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  padding: 2rem 1rem 1rem;
}
.list-view .product-list-cell.highlighted-product, .grid-view .product-list-cell.highlighted-product, .small-grid-view .product-list-cell.highlighted-product {
  padding-bottom: 2.5rem;
}
.list-view .product-list-cell.grouped, .grid-view .product-list-cell.grouped, .small-grid-view .product-list-cell.grouped {
  margin: 0 0.5rem;
  padding-bottom: 2.5rem;
}
.list-view .product-list-col, .grid-view .product-list-col, .small-grid-view .product-list-col {
  margin-bottom: 1rem;
}
.list-view .product-list-col .review-summary-actions, .grid-view .product-list-col .review-summary-actions, .small-grid-view .product-list-col .review-summary-actions {
  display: inline-block;
}
.list-view .product-list-col .product-title, .grid-view .product-list-col .product-title, .small-grid-view .product-list-col .product-title {
  display: inline-block;
  margin-top: 1rem;
  font-size: 1.1428571429rem;
  font-weight: normal;
}
.list-view .product-list-col .product-title.pop-printers, .grid-view .product-list-col .product-title.pop-printers, .small-grid-view .product-list-col .product-title.pop-printers {
  display: inline-block;
  margin-top: 1rem;
}
.list-view .pop-printers a, .grid-view .pop-printers a, .small-grid-view .pop-printers a {
  font-size: 1.1428571429rem;
}
.list-view .product-list-details, .grid-view .product-list-details, .small-grid-view .product-list-details {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.list-view .product-list-details .price-saving, .grid-view .product-list-details .price-saving, .small-grid-view .product-list-details .price-saving {
  font-size: 1.0714285714rem;
  color: #238247;
  margin-bottom: 1rem;
}
.list-view .product-list-details .grid-review, .grid-view .product-list-details .grid-review, .small-grid-view .product-list-details .grid-review {
  margin-bottom: 1rem;
}
.list-view .catalog-add-cart-form, .grid-view .catalog-add-cart-form, .small-grid-view .catalog-add-cart-form {
  position: relative;
}
.list-view .catalog-add-cart-form.grouped, .grid-view .catalog-add-cart-form.grouped, .small-grid-view .catalog-add-cart-form.grouped {
  margin-top: 1.5rem;
}

.grid-view .product-list-details.grouped, .small-grid-view .product-list-details.grouped {
  flex-grow: 1;
}

.list-view .product-list-cell {
  border-bottom: 1px solid #cccccc;
}
.list-view .product-list-cell.last, .list-view .product-list-cell:last-child {
  border-bottom: 0;
}

.product-carousel-wrapper {
  margin: 4rem 0 5rem;
}
.product-carousel .slick-slider {
  display: flex;
}
.product-carousel .slick-list {
  display: flex;
}
.product-carousel .slick-track {
  display: flex;
}
.product-carousel .grid-view .product-list-cell {
  display: flex !important;
  flex-direction: column;
  border: 0;
  margin: 0 1rem;
  max-width: 300px;
  min-width: 300px;
  min-height: 32.1428571429rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-carousel .grid-view .product-image-photo {
  margin: 0 auto 1rem;
}
.product-carousel .product-title {
  position: relative;
  font-size: 1.0714285714rem;
  height: 3.5rem;
  overflow: hidden;
}
.product-carousel .product-title:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0.3571428571rem;
  right: 0;
  width: 40%;
  height: 1.2857142857rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 50%);
}
.product-carousel .review-summary {
  font-size: 1.0714285714rem;
  margin-top: 0.25rem;
}
.product-carousel .price-final_price .price-wrapper {
  font-size: 1.7857142857rem;
}
.product-carousel .price-box {
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 48em) {
  .list-view .product-list-cell, .grid-view .product-list-cell, .small-grid-view .product-list-cell {
    margin: 1rem 0.5rem;
    justify-content: space-between;
  }
  .list-view .atc-button, .grid-view .atc-button, .small-grid-view .atc-button {
    padding: 0.7142857143rem 0;
  }
  .list-view .product-list-cell {
    flex-direction: row;
  }
  .list-view .product-list-image .product-image-container {
    max-width: 100%;
    margin: 0 auto;
  }
  .list-view .product-list-image img {
    max-width: 100%;
    width: auto;
  }
  .list-view .product-list-top {
    flex-direction: column;
    max-width: 65%;
    margin-top: 0.5rem;
    margin-right: 1rem;
  }
  .list-view .product-list-top .product-title {
    margin-top: 0;
  }
  .list-view .product-list-image {
    float: left;
    max-width: 20%;
    margin-right: 1rem;
  }
  .list-view .product-list-col {
    float: left;
    max-width: calc(80% - 1rem);
  }
  .list-view .auto-spacer {
    display: none;
  }
  .list-view .product-list-details {
    margin-top: 0.5rem;
  }
  .product-carousel .grid-view .product-list-cell.last, .product-carousel .small-grid-view .product-list-cell.last {
    border: 0;
  }
  .grid-view .product-list-image img, .small-grid-view .product-list-image img {
    max-width: 150px;
  }
  .grid-view .product-list-cell, .small-grid-view .product-list-cell {
    float: left;
  }
  .grid-view .product-list-top, .small-grid-view .product-list-top {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
  }
  .grid-view .product-list-cell {
    max-width: calc(50% - 1rem);
  }
  .small-grid-view .product-list-cell {
    max-width: calc(33.33% - 1rem);
  }
  .small-grid-view .atc-qty {
    width: 100%;
    margin: 0 0 1rem;
  }
  .small-grid-view .atc-qty-wrapper {
    min-width: 100%;
  }
  .small-grid-view .atc-button {
    margin-left: 0;
    width: 100%;
    padding: 1rem 0.7142857143rem;
  }
  .small-grid-view .atc-button-wrapper {
    margin: 0;
    min-width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .list-view .product-list-cell {
    justify-content: space-between;
  }
  .list-view .product-list-image {
    margin-right: 2rem;
    min-width: 10.7142857143rem;
  }
  .list-view .product-list-col {
    max-width: calc(80% - 2rem);
  }
  .list-view .product-list-top {
    max-width: 70%;
    margin-right: 2rem;
  }
  .list-view .product-list-top.grouped {
    flex-grow: 1;
  }
  .list-view .atc-button {
    width: 100%;
    padding: 0.7142857143rem 0;
  }
  .list-view .atc-button.grouped {
    padding: 1rem 1.4285714286rem;
  }
  .grid-view .product-list-cell {
    max-width: calc(33.33% - 1rem);
  }
  .small-grid-view .product-list-cell {
    max-width: calc(25% - 1rem);
  }
  .small-grid-view .atc-button {
    padding: 0.7142857143rem 0;
  }
}
@media screen and (min-width: 75em) {
  .small-grid-view .atc-qty {
    width: auto;
    margin: 0;
  }
  .small-grid-view .atc-qty-wrapper {
    min-width: initial;
  }
  .small-grid-view .atc-button-wrapper {
    margin-left: 1rem;
    min-width: initial;
  }
}
.our-price {
  margin: 0.5rem 0 0;
  font-size: 1.1428571429rem;
  font-weight: 600;
  font-style: italic;
  line-height: 1;
}

.price-box {
  display: inline-block;
  vertical-align: baseline;
}
.price-label {
  font-size: 1.1428571429rem;
  font-style: italic;
  font-weight: 600;
  display: block;
  line-height: 1;
}
.price-final_price .price-wrapper {
  font-size: 2rem;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
}
.price-final_price .price-wrapper:after {
  content: "ea";
  margin-left: 0.75rem;
  font-size: 1.2857142857rem;
  font-weight: normal;
}
.price-tier_price .price-wrapper {
  font-weight: 700;
}
.price-msrp {
  vertical-align: middle;
  margin-bottom: 0.5rem;
}
.price-saving {
  margin: 0 0 2rem;
}

.price-container {
  position: relative;
  display: block;
}
.price-container .animation-icon {
  position: absolute;
  top: 8px;
  right: -1.7857142857rem;
  font-size: 1.1428571429rem;
}
.price-container .animation-icon.down {
  color: #238247;
  animation: bounce 0.3s linear;
}
.price-container .animation-icon.up {
  animation: bounce 0.3s linear reverse;
}

.product-list-details .price-container .animation-icon {
  font-size: 0.8571428571rem;
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20% {
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  40% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
    transform: translateY(2px);
  }
  60% {
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    transform: translateY(4px);
  }
  80% {
    -webkit-transform: translateY(8px);
    -ms-transform: translateY(8px);
    transform: translateY(8px);
  }
  100% {
    -webkit-transform: translateY(8px);
    -ms-transform: translateY(8px);
    transform: translateY(8px);
  }
}
.catalog-product-view .product-info-price {
  margin-top: 0;
}
.catalog-product-view .product-info-price .items-in-cart {
  margin-bottom: 0;
}

.product-info-price {
  background: #efefef;
  border-radius: 0 0 0 0;
  padding: 2rem;
  margin: 2rem 0 0;
}
.product-info-price .price-final_price .price-wrapper {
  font-size: 2.5rem;
}
.product-info-price .price-box {
  vertical-align: baseline;
}
.product-info-price .msrp-price {
  display: block;
  font-size: 1.0714285714rem;
  vertical-align: baseline;
}
.product-info-price .items-in-cart {
  margin: 0.5rem 0 1.75rem;
}

.prices-tier-wrapper {
  padding-right: 0;
}

.product-store-credit-discount {
  border-top: 1px solid #cccccc;
  margin-top: 1.25rem;
  padding-top: 0.75rem;
}

.prices-tier.items {
  border: 1px solid #cccccc;
  border-radius: 0;
  border-collapse: initial;
  border-spacing: 0;
  margin: 1.5rem 0 0;
}
.prices-tier.items tbody th, .prices-tier.items tbody td {
  padding: 0.5rem;
}
.prices-tier.items tr:first-child th {
  border-top-left-radius: 0;
}
.prices-tier.items tr:first-child td {
  border-top-right-radius: 0;
}
.prices-tier.items tr:last-child th {
  border-bottom-left-radius: 0;
}
.prices-tier.items tr:last-child td {
  border-bottom-right-radius: 0;
}

.free-ship {
  display: block;
  margin-top: 1.5rem;
}

@media screen and (min-width: 48em) {
  .price-box {
    margin-left: 0;
  }
  .product-store-credit-discount {
    padding-top: 1rem;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 75em) {
  .product-info-price .price-saving {
    display: inline-block;
    margin-left: 2rem;
  }
}
.catalogsearch-result-index .main-content {
  max-width: 85.7142857143rem;
  margin-left: auto;
  margin-right: auto;
}

.algolia-instant-selector-results .algolia-infos {
  background: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 1rem;
  border-bottom: 2px solid #3D3B3B;
  color: #3D3B3B;
  font-size: 1.1428571429rem;
}
.algolia-instant-selector-results .ais-SearchBox-input {
  padding: 0.75rem 1.0714285714rem;
  height: auto;
  width: 100%;
  margin-top: 0.5rem;
}
.algolia-instant-selector-results .ais-SearchBox-reset {
  top: 5px;
  right: 5px;
}
.algolia-instant-selector-results .ais-SearchBox-reset .ais-SearchBox-resetIcon {
  fill: #707070;
}
.algolia-instant-selector-results .ais-SearchBox-reset:hover .ais-SearchBox-resetIcon {
  fill: #EC2748;
}
.algolia-instant-selector-results .ais-Hits-item {
  text-indent: 0;
  text-align: left;
}
.algolia-instant-selector-results .ais-Hits-item:before {
  display: none;
}
.algolia-instant-selector-results .ais-Hits-item mark {
  color: #3D3B3B;
  background-color: rgba(243, 201, 18, 0.5);
  font-weight: 700;
}

.product-list-image img {
  width: inherit;
}

.ais-SortBy-select, .ais-HitsPerPage-select {
  margin: 0;
}

.brand-wrapper .brand-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
}
.brand-wrapper .brand-list.featured {
  font-size: 1.4285714286rem;
}
.brand-wrapper .brand-list li {
  margin: 1rem;
}
.brand-wrapper .brand-list li:before {
  display: none;
}
.brand-wrapper .brand-list li a {
  color: #5c5c5c;
}
.brand-wrapper .brand-list li a:hover {
  color: #3D3B3B;
  text-decoration: none;
}

@media screen and (min-width: 48em) {
  .brand-wrapper .brand-list li {
    margin: 0.5rem 1rem;
  }
}