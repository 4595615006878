//--- View Toggle
%valign-middle {
    vertical-align: middle;
}

.section-wrapper {
    margin: 3rem auto 0;
    .printer-section-title {
        color: $dark-gray;
        margin-bottom: 1rem;
    }
}

.ko-product {
    .toolbar-wrapper.row {
        background: transparent;
        border-bottom: 2px solid $medium-gray;
        padding: 1rem;
        margin-bottom: 1.5rem;
        .toolbar-header{
            color: $dark-gray;
        }
    }
}

.toolbar-wrapper {
    .toolbar-header {
        color: $white;
    }
    .toolbar-label {
        @extend %valign-middle;
        margin-right: 1rem;
        font-weight: 600;
        font-size: rem-calc(16);
    }
    .toggle-switch {
        @extend %valign-middle;
        color: $ui-gray;
        font-size: rem-calc(26);
        @include disable-mouse-outline;
        cursor: pointer;
        margin: 0 .5rem;
        &:hover, &.active {
            color: get-color('blue');
        }
        &.last {
            margin-right: 0;
        }
    }
    .limiter-label {
        display: inline;
    }
}

@media screen and #{breakpoint(medium)} {
    .section-wrapper {
        border-bottom: 2px solid darken($dark-gray, 5%);
        padding: 1rem;
        margin: 2rem auto 0;
        &.printer-section-wrapper {
            border-bottom: 0;
            background: darken($dark-gray, 5%);
            margin: 4rem auto 0;
        }
        .printer-section-title {
            color: $white;
            margin-bottom: 0;
        }
    }
    .toolbar-amount {
        font-size: rem-calc(16);
    }
}
