@import '../../styles/vendor/icomoon/variables/variables';

//--- Product pricing
.our-price {
    margin: .5rem 0 0;
    font-size: rem-calc(16);
    font-weight: 600;
    font-style: italic;
    line-height: 1;
}

%pricingAnimation {
    font-family: $icomoon-font-family;
    top: 0;
    font-size: 40%;
    right: -50px;
    position: absolute;
}

.price {
    &-box {
        display: inline-block;
        vertical-align: baseline;
    }
    &-label {
        font-size: rem-calc(16);
        font-style: italic;
        font-weight: 600;
        display: block;
        line-height: 1;
    }
    &-final_price {
        .price-wrapper {
            font-size: rem-calc(28);
            font-weight: 700;
            font-style: normal;
            line-height: 1;
            &:after {
                content: "ea";
                margin-left: 0.75rem;
                font-size: rem-calc(18);
                font-weight: normal;
            }
        }
    }
    &-tier_price {
        .price-wrapper {
            font-weight: 700;
        }
    }
    &-msrp {
        vertical-align: middle;
        margin-bottom: 0.5rem;
    }
    &-saving {
        margin: 0 0 2rem;
    }
}

.price-container {
    position: relative;
    display: block;
    .animation-icon{
        position: absolute;
        top: 8px;
        right: rem-calc(-25);
        font-size: rem-calc(16);
        &.down {
            color: get-color('green');
            animation: bounce .3s linear;
        }
        &.up {
            animation: bounce .3s linear reverse;
        }
    }
}
.product-list-details {
    .price-container {
        .animation-icon {
            font-size: rem-calc(12);
        }
    }
}
@keyframes bounce{
    0% {
        -webkit-transform: translateY(-8px);
        -ms-transform: translateY(-8px);
        transform: translateY(-8px);
    }
    20% {
        -webkit-transform: translateY(-4px);
        -ms-transform: translateY(-4px);
        transform: translateY(-4px);
    }
    40% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(2px);
        -ms-transform: translateY(2px);
        transform: translateY(2px);
    }
    60% {
        -webkit-transform: translateY(4px);
        -ms-transform: translateY(4px);
        transform: translateY(4px);
    }
    80% {
        -webkit-transform: translateY(8px);
        -ms-transform: translateY(8px);
        transform: translateY(8px);
    }
    100% {
        -webkit-transform: translateY(8px);
        -ms-transform: translateY(8px);
        transform: translateY(8px);
    }
}
.catalog-product-view {
    .product-info-price {
        margin-top: 0;
    }
    .product-info-price .items-in-cart {
        margin-bottom: 0;
    }
}

.product-info-price {
    background: $light-gray;
    border-radius: $global-radius $global-radius 0 0;
    padding: 2rem;
    margin: 2rem 0 0;
    .price {
        &-final_price {
            .price-wrapper {
                font-size: rem-calc(35);
            }
        }
    }
    .price-box {
         vertical-align: baseline;
    }

    .msrp-price {
        display: block;
        font-size: rem-calc(15);
        vertical-align: baseline;
    }

    .items-in-cart {
        margin: .5rem 0 1.75rem;
    }
}

.prices-tier-wrapper {
    padding-right: 0;
}

.product-store-credit-discount {
    border-top: $global-border;
    margin-top: 1.25rem;
    padding-top: .75rem;
}

.prices-tier.items {
    border: $global-border;
    border-radius: $global-radius;
    border-collapse: initial;
    border-spacing: 0;
    margin: 1.5rem 0 0;
    tbody {
        th, td {
            padding: .5rem;
        }
    }
    tr {
        &:first-child {
            th {
                border-top-left-radius: $global-radius;
            }
            td {
                border-top-right-radius: $global-radius;
            }
        }
        &:last-child {
            th {
                border-bottom-left-radius: $global-radius;
            }
            td {
                border-bottom-right-radius: $global-radius;
            }
        }
    }
}

.free-ship {
    display: block;
    margin-top: 1.5rem;
}

@media screen and #{breakpoint(medium)} {
    .price-box {
        margin-left: 0;
    }
    .product-store-credit-discount {
        padding-top: 1rem;
        margin-top: 1rem;
    }
    .product-info-price {

    }
}

@media screen and #{breakpoint(xlarge)} {
    .product-info-price {
        .price {
            &-saving {
                display: inline-block;
                margin-left: 2rem;
            }
        }
    }
}
